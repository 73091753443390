import { useEffect, useState } from 'react';

export function usePreferredColorScheme() {
  const query = window.matchMedia('(prefers-color-scheme: dark)');
  const getScheme = (q: MediaQueryList | MediaQueryListEvent) => q.matches ? 'dark' : 'light';
  const [scheme, setScheme] = useState(getScheme(query));

  useEffect(() => {
    if (!query || !query.addEventListener) {
      // prefers color scheme not supported
      return;
    }
    query.addEventListener('change', e => {
      setScheme(getScheme(e));
    });
  }, [query]);

  return scheme;
}
