import {
  CollectionsBookmark as BookIcon,
  CollectionsBookmarkOutlined as BookOutlinedIcon,
  LocalActivity as HomeIcon,
  LocalActivityOutlined as HomeOutlinedIcon,
  ContactPage as MyPageIcon,
  ContactPageOutlined as MyPageOutlinedIcon,
  SearchRounded as SearchIcon,
  SearchOutlined as SearchOutlinedIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { BottomNav, BottomNavItem } from '@uzdem/bottom-nav';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as NavIcon } from '../assets/nav-icon.svg';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '28px',
    height: '28px',
  },
}));

export default function BottomNavigation() {
  const { t } = useTranslation();
  const theme = useTheme();
  const loc = useLocation();
  const classes = useStyles();
  const nav = useNavigate();

  const pathEls = loc.pathname.split('/');
  let firstPath = pathEls.length >= 1 ? pathEls[1] : '';

  return (
    <BottomNav
      maxWidth={600}
    >
      <BottomNavItem
        label={t('bottomNavigation.explore')}
        icon={(firstPath === '') ? <HomeIcon className={classes.icon} /> : <HomeOutlinedIcon className={classes.icon} />}
        isActive={firstPath === ''}
        onClick={() => { nav('/') }}
      />
      <BottomNavItem
        label={t('bottomNavigation.search')}
        icon={(firstPath === 'search') ? <SearchIcon className={classes.icon} /> : <SearchOutlinedIcon className={classes.icon} />}
        isActive={firstPath === 'search'}
        onClick={() => { nav('/search') }}
      />
      <BottomNavItem
        label={t('bottomNavigation.courses')}
        icon={(firstPath === 'courses') ? <BookIcon className={classes.icon} /> : <BookOutlinedIcon className={classes.icon} />}
        isActive={firstPath === 'courses'}
        onClick={() => { nav('/courses') }}
      />
      <BottomNavItem
        label={t('bottomNavigation.myPage')}
        icon={(firstPath === 'my-page') ? <MyPageIcon className={classes.icon} /> : <MyPageOutlinedIcon className={classes.icon} />}
        isActive={firstPath === 'my-page'}
        onClick={() => { nav('/my-page') }}
      />
      <BottomNavItem
        label={t('bottomNavigation.applications')}
        isActive={firstPath === 'contents'}
        icon={<NavIcon
          style={{
            fill: theme.palette.mode === 'dark' ? grey[50] : grey[800],
            width: 20,
            height: 20,
            margin: '3px'
          }}
        />}
        onClick={() => { nav('/contents') }}
      />
    </BottomNav>
  )
}
