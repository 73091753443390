import { CssBaseline, useMediaQuery } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { useContext } from 'react';
import { ErrorBoundary, withErrorBoundary as withError } from 'react-error-boundary';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'use-http';
import ErrorFallback from './components/ErrorFallback';
import Layout from './components/Layout';
import RoutingScrollToTop from './components/RoutingScrollToTop';
import { apiUrl } from './config';
import GuardContainer from './containers/Guard';
import { ConfirmDialogProvider } from './contexts/ConfirmDialog';
import { DynamicThemeContext, DynamicThemeProvider } from './contexts/DynamicThemeProvider';
import { StateContext, StateProvider } from './contexts/State';
import usePersistedToken from './hooks/usePersistedToken';
import './i18n';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import { ConfigProvider } from './contexts/ConfigContext';

const MyPage = React.lazy(() => import('./views/MyPage'));
const Logout = React.lazy(() => import('./views/Logout'));
const PageNotFound = React.lazy(() => import('./views/PageNotFound'));
const RefreshToken = React.lazy(() => import('./views/RefreshToken'));
const SharedQuizzes = React.lazy(() => import('./views/SharedQuizzes'));
const Search = React.lazy(() => import('./views/Search'));
const CategoriesMng = React.lazy(() => import('./views/CategoriesMng'));
const CategoryAddEdit = React.lazy(() => import('./views/CategoryAddEdit'));
const Contents = React.lazy(() => import('./views/Contents'));
const CourseAddEdit = React.lazy(() => import('./views/CourseAddEdit'));
const CourseDetail = React.lazy(() => import('./views/CourseDetail'));
const Courses = React.lazy(() => import('./views/Courses'));
const Home = React.lazy(() => import('./views/Home'));
const LevelAddEdit = React.lazy(() => import('./views/LevelAddEdit'));
const Levels = React.lazy(() => import('./views/Levels'));
const Login = React.lazy(() => import('./views/Login'));
const SectionAddEdit = React.lazy(() => import('./views/SectionAddEdit'));
const TagAddEdit = React.lazy(() => import('./views/TagAddEdit'));
const Tags = React.lazy(() => import('./views/Tags'));
const Unit = React.lazy(() => import('./views/Unit'));
const UnitAddEdit = React.lazy(() => import('./views/UnitAddEdit'));
const PasswordReset = React.lazy(() => import('./views/PasswordReset'));
const PasswordRefresh = React.lazy(() => import('./views/PasswordRefresh'));
const Slides = React.lazy(() => import('./views/Slides'));
const SlideAddEdit = React.lazy(() => import('./views/SlideAddEdit'));

const withErrorBoundary = (component: React.ComponentType) => {

  const errorFunction = (error: any) => ErrorFallback({ error });

  return withError(component, { FallbackComponent: errorFunction })
}

declare var GA_TRACKING_ID: string;
declare var META_DESCRIPTION: string;

function initialGA() {
  ReactGA.initialize(GA_TRACKING_ID, { testMode: process.env.NODE_ENV === 'test' });
  ReactGA.pageview(window.location.toString());
}

function AppWithState() {
  // auto persist & load auth token hook
  usePersistedToken();

  const { theme } = useContext(DynamicThemeContext);
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

  //lang set from local storage language
  document.documentElement.lang = window.localStorage.i18nextLng;

  const CourseAddEditWithError = withErrorBoundary(CourseAddEdit);
  const SectionAddEditWithError = withErrorBoundary(SectionAddEdit);
  const UnitWithError = withErrorBoundary(Unit);
  const UnitAddEditWithError = withErrorBoundary(UnitAddEdit);
  const TagsWithError = withErrorBoundary(Tags);
  const TagAddEditWithError = withErrorBoundary(TagAddEdit);
  const LevelsWithError = withErrorBoundary(Levels);
  const LevelAddEditWithError = withErrorBoundary(LevelAddEdit);
  const ContentsWithError = withErrorBoundary(Contents);
  const CategoriesMngWithError = withErrorBoundary(CategoriesMng);
  const CategoryAddEditWithError = withErrorBoundary(CategoryAddEdit);
  const SearchWithError = withErrorBoundary(Search);
  const SharedQuizzesWithError = withErrorBoundary(SharedQuizzes);
  const LogoutWithError = withErrorBoundary(Logout);
  const PageNotFoundWithError = withErrorBoundary(PageNotFound);
  const SlidesWithError = withErrorBoundary(Slides);
  const SlideAddEditWithError = withErrorBoundary(SlideAddEdit);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta name="theme-color" content={theme.palette.primary.main} />
          <meta name="description" content={META_DESCRIPTION} />
        </Helmet>
        <CssBaseline enableColorScheme />
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset" element={<PasswordReset />} />
            <Route path="/auth/password/reset" element={<PasswordReset />} />
            <Route path="/refresh-token" element={<RefreshToken />} />
            <Route path="/logout" element={<LogoutWithError />} />
          </Routes>
          <GuardContainer>
            <ConfigProvider>
              <Layout>
                <ErrorBoundary
                  FallbackComponent={error => ErrorFallback({ error, fullScreen: true })}
                >
                  <RoutingScrollToTop>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/my-page" element={<MyPage />} />
                      <Route path="/courses" element={<Courses />} />
                      <Route path="/courses/new" element={<CourseAddEditWithError />} />
                      {matchesLg ? (
                        <>
                          <Route path="/courses/:courseId" element={<CourseDetail />} />
                          <Route path="/courses/:courseId/sections/:sectionId/units/:unitId" element={<Unit />} />
                        </>
                      ) : (
                        <Route path="/courses/:courseId" element={<CourseDetail />}>
                          <Route path="sections/:sectionId/units/:unitId" element={<Unit />} />
                        </Route>
                      )}
                      <Route path="/courses/:courseId/edit" element={<CourseAddEditWithError />} />
                      <Route path="/courses/:courseId/sections/:sectionId" element={<UnitWithError />} />
                      <Route path="/courses/:courseId/sections/new" element={<SectionAddEditWithError />} />
                      <Route path="/courses/:courseId/sections/:sectionId/edit" element={<SectionAddEditWithError />} />
                      <Route path="/courses/:courseId/sections/:sectionId/units/new" element={<UnitAddEditWithError />} />
                      <Route path="/courses/:courseId/sections/:sectionId/units/:unitId/edit" element={<UnitAddEditWithError />} />
                      <Route path="/tags" element={<TagsWithError />} />
                      <Route path="/tags/new" element={<TagAddEditWithError />} />
                      <Route path="/tags/:tagId/edit" element={<TagAddEditWithError />} />
                      <Route path="/levels" element={<LevelsWithError />} />
                      <Route path="/levels/new" element={<LevelAddEditWithError />} />
                      <Route path="/levels/:levelId/edit" element={<LevelAddEditWithError />} />
                      <Route path="/contents" element={<ContentsWithError />} />
                      <Route path="/categories" element={<CategoriesMngWithError />} />
                      <Route path="/categories/:categoryId/edit" element={<CategoryAddEditWithError />} />
                      <Route path="/categories/new" element={<CategoryAddEditWithError />} />
                      <Route path="/slides" element={<SlidesWithError />} />
                      <Route path="/slides/new" element={<SlideAddEditWithError />} />
                      <Route path="/slides/:slideId/edit" element={<SlideAddEditWithError />} />
                      <Route path="/search" element={<SearchWithError />} />
                      <Route path="/shared-exams" element={<SharedQuizzesWithError />} />
                      <Route path="/password-refresh" element={<PasswordRefresh />} />
                      <Route path="*" element={<PageNotFoundWithError />} />
                    </Routes>
                  </RoutingScrollToTop>
                </ErrorBoundary>
              </Layout>
            </ConfigProvider>
          </GuardContainer>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function AppWithProviders() {
  const { state } = useContext(StateContext);

  const options: any = {
    interceptors: {
      // every time we make an http request, this will run 1st before the request is made
      // url, path and route are supplied to the interceptor
      // request options can be modified and must be returned
      request: async ({ options }: any) => {
        options.headers.Authorization = `Bearer ${state.auth.token}`
        return options
      }
    }
  };

  return (
    <Provider url={apiUrl} options={options}>
      <DynamicThemeProvider>
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          )}
        >
          <AppWithState />
        </SnackbarProvider>
      </DynamicThemeProvider>
    </Provider>
  )
}

function App() {
  initialGA();
  return (
    <StateProvider>
      <ConfirmDialogProvider>
        <AppWithProviders />
      </ConfirmDialogProvider>
    </StateProvider>
  );
}

export default App;
