import {
  ContactSupport as QuizIcon, InsertDriveFileRounded,
  PlayCircleFilledRounded, Search as SearchIcon
} from '@mui/icons-material';
import { Autocomplete, Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import defaultCover from '../assets/default-cover.png';
import { MSHits, UnitMetaType } from '../client';
import { useDeviceDetect } from '../hooks/useDeviceDetect';
import { grey } from '@mui/material/colors';
import AipImage from './AipImage';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '50ch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputBase: {
    color: 'inherit',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.paper,
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  popperComponent: {
    position: 'relative',
    height: 500,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    }
  },
  listItemTypography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },
  avatar: {
    backgroundColor: theme.palette.mode === 'dark' ? grey[700] : grey[300],
  }
}));

type ExploreSearchProps = {
  searchResults: MSHits[];
  inputValue: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
}

export default function ExploreSearch({ inputValue, setSearchText, searchResults, onClose }: ExploreSearchProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    const escFunction = (e: any) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keyup', escFunction, false);
    return () => {
      document.addEventListener('keyup', escFunction, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUnitIcon = (unitType: UnitMetaType) => {
    if (unitType === 'VIMEO_VIDEO' || unitType === 'VMS_VIDEO') return <PlayCircleFilledRounded color="primary" />;
    if (unitType === 'ONLINE_QUIZ' || unitType === 'SHARED_QUIZ_POOL' || unitType === 'SURVEY_QUIZ' || unitType === 'TRIAL_QUIZ') return <QuizIcon color="primary" />;
    return <InsertDriveFileRounded color="primary" />;
  }

  const getHighlightSnippets = (text?: string) =>
    text?.replaceAll('<mark>', `<span style="color:${theme.palette.primary.main};">`).replaceAll('</mark>', '</span>') || '';

  return (
    <div className={classes.root}>
      <Autocomplete
        id="search"
        options={searchResults}
        className={classes.inputBase}
        autoHighlight={true}
        open={true}
        groupBy={(option) => option.document.course ? t('exploreSearch.contents') : t('exploreSearch.courses')}
        freeSolo={true}
        forcePopupIcon={false}
        isOptionEqualToValue={(option, value) => option.document.id === value.document.id}
        getOptionLabel={(option) => typeof (option) === 'string' ? option : option.document.name}
        filterOptions={(x) => x}
        ListboxComponent={(props) => <List {...props} sx={{ maxHeight: '100% !important' }} />}
        PaperComponent={(props) => <Paper elevation={0} {...props} style={{ background: 'transparent', height: '100%' }} />}
        PopperComponent={(props) => <div {...props} className={classes.popperComponent} />}
        renderOption={(props, option) => {
          const path = option.document.course ? `/courses/${option.document.courseId}/sections/${option.document.sectionId}/units/${option.document.id}` : `/courses/${option.document.id}`;
          const nameSnippets = option.highlights.find(h => h.field === 'name')?.snippet || option.document.name; // course or unit name highlights
          const unitCourseSnippets = option.highlights.find(h => h.field === 'course')?.snippet || option.document.course;
          const unitSectionSnippets = option.highlights.find(h => h.field === 'section')?.snippet || option.document.section;
          const categorySnippets = option.highlights.find(h => h.field === 'category')?.snippet || option.document.category;

          return (
            <ListItem button divider component={Link} to={path} onClick={() => onClose()}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  {option.document.course ? (
                    getUnitIcon(option.document.type as UnitMetaType)
                  ) : (
                    <AipImage
                      imageUrl={option.document.coverImgUrl || null}
                      defaultImage={defaultCover}
                      height={40}
                      imageSize={400}
                    />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ className: classes.listItemTypography }}
                secondaryTypographyProps={{ className: classes.listItemTypography }}
                primary={<span dangerouslySetInnerHTML={{ __html: getHighlightSnippets(nameSnippets) }} />}
                secondary={
                  <span dangerouslySetInnerHTML={{ __html: getHighlightSnippets(option.document.course ? `${unitCourseSnippets} - ${unitSectionSnippets}` : categorySnippets) }} />
                }
              />
            </ListItem>
          )
        }}
        renderInput={(params) => (
          <TextField
            onChange={(e) => setSearchText(e.target.value)}
            {...params}
            fullWidth
            autoFocus
            focused
            placeholder={t('exploreSearch.search')}
            InputProps={{
              ...params.InputProps,
              style: { padding: 9 },
              startAdornment: (
                <React.Fragment>
                  {<SearchIcon color="primary" />}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    onClose();
                  }}
                  sx={{
                    padding: 0,
                    textTransform: 'none'
                  }}
                >
                  {isMobile ? t('exploreSearch.close') : 'esc'}
                </Button>
              )
            }}
          />
        )}
      />
    </div>
  );
}