import { ImgHTMLAttributes, useContext, useEffect, useRef, useState } from 'react';
import ConfigContext from '../contexts/ConfigContext';
import { imageUrlConvertWebpSrcList } from '../utils';

interface AipImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  imageUrl: string | null;
  defaultImage?: string;
  imageSize?: number;
}

export default function AipImage({ imageUrl, defaultImage, imageSize, ...props }: AipImageProps) {
  const ref = useRef(null);
  const [inView, setInView] = useState(false);
  const { config } = useContext(ConfigContext);
  const src = imageUrl ? imageUrlConvertWebpSrcList(config.aipUrl, imageUrl, imageSize)[0] : defaultImage;
  const srcSet = imageUrl ? imageUrlConvertWebpSrcList(config.aipUrl, imageUrl, imageSize).join(', ') : defaultImage;

  useEffect(() => {
    const observer = new IntersectionObserver((entries, obs) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setInView(true);
          obs.disconnect();
        }
      }
    }, {});
    ref.current && observer.observe(ref.current);
    return () => {
      observer.disconnect();
    }
  }, []);

  return inView ? (
    <img
      src={src}
      srcSet={srcSet}
      {...props}
      alt={props.alt}
      ref={ref}
    />
  ) : (
    <img src={defaultImage} {...props} alt={props.alt} ref={ref} />
  );
}