import { useCallback, useContext } from 'react';
import { StateContext } from '../contexts/State';
import { CacheActions } from '../reducers/cache';

export default function useCache(key: string) {
  const { state, dispatch } = useContext(StateContext);

  const cacheId = state.cache.values[key];

  const invalidateCache = useCallback(() => {
    dispatch({ type: CacheActions.Invalidate, key })
  }, [dispatch, key]);

  return {
    cacheId, invalidateCache
  }
}
