export enum PreferenceActions {
  ChangeLocale = 'ChangeLocale',
}

export type PreferenceAction = { type: PreferenceActions.ChangeLocale, locale: string };

export type PreferenceState = {
  locale?: string
}

export const preferenceReducer = (state: PreferenceState, action: PreferenceAction) => {
  switch (action.type) {
    case PreferenceActions.ChangeLocale:
      return { locale: action.locale }
    default:
      return state;
  }
}
