export enum CacheActions {
  Invalidate = 'Invalidate',
}

export type CacheAction =
  { type: CacheActions.Invalidate, key: string }
;

export type CacheState = {
  values: {
    [key: string]: number
  }
}

export const cacheReducer = (state: CacheState, action: CacheAction) => {
  switch (action.type) {
    case CacheActions.Invalidate:
      return { values: { ...state.values, [action.key]: Date.now() } }
    default:
      return state;
  }
}
