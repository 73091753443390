import { Grid, Paper, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';

type AppMenuItemProps = {
  title: string;
  iconSrc: string;
  itemColumnSpan?: number;
  isActive?: boolean;
  path: string;
};

export default function AppMenuItem({ title, iconSrc, itemColumnSpan, path, isActive }: AppMenuItemProps) {
  return (
    <Grid item xs={itemColumnSpan} component="a" href={path} sx={{ textDecoration: 'none' }}>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: theme => isActive ? theme.palette.primary.main + '1A' : theme.palette.background.paper,
          height: 90,
          borderRadius: 1.5,
          border: '1px solid #0009DE1C',
          px: 1,
          py: 0.5,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme => theme.palette.action.hover
          },
        }}
      >
        <Typography variant="h6" fontSize="1rem" sx={{ position: 'relative', zIndex: 1 }}>{title}</Typography>
        <ReactSVG
          src={iconSrc}
          style={{ position: 'absolute', right: 8, bottom: 0 }}
          beforeInjection={(svg) => {
            svg.setAttribute('height', '50px');
            svg.setAttribute('width', 'auto');
          }}
        />
      </Paper>
    </Grid>
  )
}