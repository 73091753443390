import { Collapse, ListItem, ListItemIcon, useTheme } from '@mui/material';
import {
  ArrowDropDownRounded as ArrowDropDownIcon,
  ArrowRightRounded as ArrowRightIcon
} from '@mui/icons-material';
import React, { ReactElement, useState } from 'react';
import { getSideNavStyle } from '../utils';

type ExpandableListItemProps = {
  content: ReactElement;
  collapse: ReactElement;
  expanded?: boolean;
  onClick?: () => void;
  paddingLeft: number;
};

export default function ExpandableListItem({ content, collapse, expanded, onClick, paddingLeft }: ExpandableListItemProps) {
  const [open, setOpen] = useState(expanded);
  const theme = useTheme();

  const toggle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
    if (onClick) onClick();
  };

  return (
    <>
      <ListItem
        button
        onClick={toggle}
        sx={getSideNavStyle({ theme, level: 2, paddingLeft: paddingLeft - 16 })}>
        <ListItemIcon sx={{ minWidth: '24px' }}>
          {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </ListItemIcon>
        {content}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {collapse}
      </Collapse>
    </>
  );
}
