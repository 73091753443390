import React, { useContext } from 'react';
import { StateContext } from '../contexts/State';
import { Permission } from '../reducers/auth';

type HasPermissionProps = {
  to: Permission[],
  children: React.ReactNode
}

export default function HasPermission({ to, children }: HasPermissionProps) {
  const { state } = useContext(StateContext);

  return (
    <React.Fragment>
      {to.every((p) => state.auth.permissions?.includes(p)) &&
        children
      }
    </React.Fragment>
  )
}
