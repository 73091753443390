import WarningIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ConfigContext from '../contexts/ConfigContext';

type ErrorFallbackProps = {
  error: any,
  fullScreen?: boolean
}

export default function ErrorFallback({ error, fullScreen }: ErrorFallbackProps) {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }} >
      <WarningIcon sx={{ width: 250, height: 250 }} color="primary" />
      <Typography variant="h4">{t('errorFallback.warningText')}</Typography>
      <Typography
        sx={{
          border: '1px solid rgb(255, 217, 128)',
          backgroundColor: theme => theme.palette.mode === 'light' ? 'rgb(255, 249, 235)' : 'rgba(219, 154, 0, 0.09)',
          padding: '10px 20px',
          margin: '20px 0px'
        }}>{error.error.message}</Typography>
      <Button
        variant="contained"
        onClick={() => { window.location.reload(); }}
        sx={{
          mt: '0.5rem',
          mb: '0.5rem'
        }}>
        {t('errorFallback.tryAgain')}
      </Button>
      {fullScreen && (
        <Button
          variant="contained"
          component="a"
          href={'/courses'}
          sx={{
            mt: '0.5rem',
            mb: '0.5rem'
          }}>
          {t('errorFallback.allCourses')}
        </Button>
      )}
      <Button
        variant="contained"
        component={Link}
        to={'/logout'}
        sx={{
          mt: '0.5rem',
          mb: '0.5rem'
        }}>
        {t('errorFallback.logout')}
      </Button>
      <Typography
        component="a"
        href={`mailto:${config.supportMail}`}
        sx={{
          textDecoration: 'none',
          fontStyle: 'italic',
          color: theme => theme.palette.text.secondary,
        }}
      >
        {t('footer.toContact', { supportMail: config.supportMail })}
      </Typography>
    </Box>
  )
}
