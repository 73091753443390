import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DynamicThemeContext } from '../contexts/DynamicThemeProvider';
import { StateContext } from '../contexts/State';

declare var LANDING_ROUTE: string;

type GuardProps = {
  children: React.ReactNode;
}

const nonAuthRoutes = ['/login', '/logout', '/reset', '/auth/password/reset'];

export default function GuardContainer({ children }: GuardProps) {
  const { state } = useContext(StateContext);
  const nav = useNavigate();
  const loc = useLocation();
  const { setCourseId } = useContext(DynamicThemeContext);

  useEffect(() => {
    if (loc.pathname.includes('/courses/')) {
      const path1 = loc.pathname.split('/courses/')[1];
      if (path1.includes('/')) {
        const path2 = path1.split('/')[0];
        setCourseId(+path2);
      } else {
        setCourseId(+path1);
      }
    } else {
      setCourseId(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.pathname]);

  useEffect(() => {
    if (LANDING_ROUTE !== '/') {
      const key = "redirectOccured";
      if (!sessionStorage.getItem(key) && window.location.pathname === '/') {
        window.location.replace(`/${LANDING_ROUTE}`)
      }
      sessionStorage.setItem(key, "redirected");
    }
  }, []);

  useEffect(() => {
    // redirect to login if not authenticated
    if (!state.auth.isRefreshing && state.auth.isAuthInit) {
      const isNeedRefesh = state.auth.expires && ((new Date(state.auth.expires).getTime() - 3600 * 1000)) < new Date().getTime();

      if (!state.auth.user && !nonAuthRoutes.includes(loc.pathname)) {
        console.log('redirecting to login');
        if (loc.pathname === '/refresh-token') {
          nav('/login?'+loc.search.slice(1));
        } else {
          nav(`/login?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
        }
      }

      // refresh session if session expired
      if (isNeedRefesh && !(loc.pathname === '/refresh-token' || loc.pathname === '/login')) {
        nav(`/refresh-token?redirect=${loc.pathname}&redirectQs=${loc.search.slice(1)}`, { replace: true });
      } else if (!isNeedRefesh && state.auth.user && (loc.pathname === '/login' || loc.pathname === '/refresh-token')) {
        console.log('redirecting to courses');
        const qs = new URLSearchParams(loc.search);
        let redirectUrl = qs.get('redirect');
        if (redirectUrl) {
          redirectUrl += '?' + qs.get('redirectQs');
        } else {
          redirectUrl = '/';
        }
        if (redirectUrl.includes('vms') || redirectUrl.includes('quiz')) {
          window.location.replace(redirectUrl)
        } else {
          nav(redirectUrl, { replace: true });
        }
      }

    }
  }, [loc.pathname, loc.search, nav, state.auth.expires, state.auth.user, state.auth.isAuthInit, state.auth.isRefreshing]);

  if (!state.auth.user || nonAuthRoutes.includes(loc.pathname)) return null;
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
}
