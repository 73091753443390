import {
  AccountCircle as AccountIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  ExitToAppOutlined as LogoutIcon,
  LockReset as LockResetIcon,
} from '@mui/icons-material';
import { Box, Divider, FormControlLabel, Menu, MenuItem, Switch } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import engFlag from '../assets/eng.png';
import trFlag from '../assets/tr.png';
import { DynamicThemeContext } from '../contexts/DynamicThemeProvider';
import { StateContext } from '../contexts/State';
import { AuthActions } from '../reducers/auth';
import { useNavigate } from 'react-router-dom';
import { useAbility } from '../hooks/useAbility';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accIcon: {
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.mode === 'dark' ? grey[300] : grey[800],
      padding: 0.1,
      mx: 2,
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 48
    },
    nameEmail: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginLeft: 16,
      backgroundColor: theme.palette.primary.main + '1A',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      borderRadius: theme.spacing(1),
    },
    name: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.text.primary,
      maxWidth: 200
    },
    email: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.text.secondary,
      maxWidth: 200
    },
    checkedIcon: {
      color: `${theme.palette.secondary.main} !important`,
    },
    switchIcon: {
      background: theme.palette.background.paper,
      borderRadius: '50%',
      color: theme.palette.mode === 'dark' ? grey[300] : grey[800],
    },
    icon: {
      color: theme.palette.mode === 'dark' ? grey[300] : grey[800],
      marginLeft: theme.spacing(1),
    },
    flagIcon: {
      width: 24,
      height: 24,
      borderRadius: '50%',
    },
  }),
);

export default function AppBarUserMenu() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  const { can } = useAbility();
  const { state, dispatch } = useContext(StateContext);
  const { darkMode, setDarkMode } = useContext(DynamicThemeContext);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleLangChange = (locale: string) => {
    i18n.changeLanguage(locale);
  }

  const getUserName = () => {
    return state.auth?.user?.first_name + ' ' + state.auth?.user?.last_name;
  }

  const logout = () => {
    dispatch({ type: AuthActions.Logout });
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleGoPasswordRefresh = () => {
    nav('/password-refresh');
    handleCloseUserMenu();
  }

  return (
    <Box sx={{ mx: 1 }}>
      <IconButton onClick={handleOpenUserMenu} className={classes.accIcon}>
        <AccountIcon sx={{ height: 30, width: 30 }} />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar-user"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box
          sx={{ minWidth: 200 }}
        >
          <Box
            sx={{
              mx: 2,
              mb: 1
            }}
            className={classes.menuItem}>
            <IconButton className={classes.accIcon}>
              <AccountIcon />
            </IconButton>
            <Box className={classes.nameEmail}>
              <Typography className={classes.name} variant="subtitle2" noWrap>
                {getUserName()}
              </Typography>
              <Typography className={classes.email} variant="caption" noWrap>
                {state.auth?.user?.email}
              </Typography>
            </Box>
          </Box>
          <MenuItem key="1">
            <FormControlLabel
              sx={{
                ml: -1,
                width: '100%'
              }}
              className={classes.menuItem}
              control={
                <Switch
                  checked={darkMode}
                  onChange={(e) => setDarkMode(e.target.checked)}
                  icon={<LightModeIcon className={classes.switchIcon} />}
                  checkedIcon={
                    <DarkModeIcon className={`${classes.switchIcon} ${classes.checkedIcon}`} />
                  }
                />
              }
              label={
                <Typography sx={{ fontSize: 16, ml: 0.5 }} noWrap>
                  {t('appBarUserMenu.darkMode')}
                </Typography>
              }
            />
          </MenuItem>
          <Divider
            sx={{
              m: '0 !important'
            }}
          />
          <MenuItem key="2">
            <FormControlLabel
              sx={{
                ml: -1,
                width: '100%'
              }}
              className={classes.menuItem}
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-switchBase': {
                      padding: 1,
                      margin: 0,
                    },
                    '& .Mui-checked + .MuiSwitch-track': {
                      background: 'red'
                    },
                  }}
                  checked={i18n.language?.includes('tr')}
                  onChange={(e) => { handleLangChange(e.target.checked ? 'tr-TR' : 'en-US') }}
                  icon={<img src={engFlag} alt="en" className={classes.flagIcon} />}
                  checkedIcon={
                    <img src={trFlag} alt="tr" className={classes.flagIcon} />
                  }
                />
              }
              label={
                <Typography sx={{ fontSize: 16, ml: 0.5 }} noWrap>
                  {t('appBarUserMenu.language')}
                </Typography>
              }
            />
          </MenuItem>
          <Divider
            sx={{
              m: '0 !important'
            }}
          />
          {can('do-change-password') && (
            <>
              <MenuItem key="3" onClick={handleGoPasswordRefresh}>
                <Box className={classes.menuItem}>
                  <LockResetIcon className={classes.icon} />
                  <Typography sx={{ fontSize: 16, ml: 3 }} noWrap>
                    {t('appBarUserMenu.passwordRefresh')}
                  </Typography>
                </Box>
              </MenuItem>
              <Divider
                sx={{
                  m: '0 !important'
                }}
              />
            </>
          )}
          <MenuItem key="4" onClick={logout}>
            <Box className={classes.menuItem}>
              <LogoutIcon className={classes.icon} />
              <Typography sx={{ fontSize: 16, ml: 3 }} noWrap>
                {t('appBarUserMenu.logout')}
              </Typography>
            </Box>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  )
}
