
import React, { useEffect, useState } from 'react';
import { apiUrl } from '../config';
import useAuthHeader from '../hooks/useAuthHeader';

export interface ConfigStateType {
  config: any;
}

export const initialConfigState: ConfigStateType = {
  config: {},
}

const ConfigContext = React.createContext<ConfigStateType>(initialConfigState);

export function ConfigProvider({ token, children }: any) {
  const [config, setConfig] = useState<any>({});
  const headers = useAuthHeader();

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    if (headers.Authorization !== '') {
      await fetch(`${apiUrl}/config/constants`, { headers })
        .then(response => response.json())
        .then(data => {
          setConfig(data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  if (Object.keys(config).length === 0) {
    return null;
  }

  return (
    <ConfigContext.Provider
      value={{
        config
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

export default ConfigContext;