import {
  AccountCircleOutlined as AccountIcon,
  AccessAlarm as EQuizIcon,
  AccessAlarmOutlined as EQuizIconOutlined,
  Menu as MenuIcon,
  LibraryBooks as QuizIcon,
  LibraryBooksOutlined as QuizIconOutlined,
  OndemandVideo as VmsIcon,
  OndemandVideoOutlined as VmsIconOutlined,
  MenuOpen as MenuOpenIcon
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { LeftBar, LeftBarItem } from '@uzdem/left-bar';
import { useLocation, useNavigate } from 'react-router-dom';
import uLogoOutlined from '../assets/u-logo-outlined.svg';
import uLogo from '../assets/u-logo.svg';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import HasPermission from '../containers/HasPermission';
import { useContext } from 'react';
import { StateContext } from '../contexts/State';
import ConfigContext from '../contexts/ConfigContext';

type LeftBarComponentProps = {
  handleToggleSideNav: () => void;
  sideNavOpen: boolean;
}

export default function LeftBarComponent({ handleToggleSideNav, sideNavOpen }: LeftBarComponentProps) {

  const loc = useLocation();
  const path = loc.pathname.split('/')[1];
  const nav = useNavigate();
  const { t } = useTranslation();
  const { state } = useContext(StateContext);
  const { config } = useContext(ConfigContext);

  return (
    <LeftBar
      width={65}
      sx={{
        zIndex: 30,
      }}
    >
      <LeftBarItem
        label=''
        onClick={handleToggleSideNav}
        icon={
          <IconButton>
            {sideNavOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        }
        sx={{
          mt: 2,
        }}
        labelSx={{
          fontSize: 10
        }}
      />
      <LeftBarItem
        onClick={() => nav('/')}
        label={t('leftBarComponent.lms')}
        isActive={path !== 'shared-exams'}
        icon={path === 'shared-exams' ?
          <ReactSVG
            src={uLogoOutlined}
            style={{ fill: 'white', marginTop: 8 }}
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', 'currentColor');
            }}
          /> :
          <ReactSVG
            src={uLogo}
            style={{ fill: 'currentColor', marginTop: 8 }}
          />
        }
        sx={{
          mt: 3,
        }}
        labelSx={{
          fontSize: 10
        }}
      />
      <HasPermission to={['search-e-quizzes']}>
        <LeftBarItem
          label={t('leftBarComponent.sharedExams')}
          onClick={() => nav('/shared-exams')}
          isActive={path === 'shared-exams'}
          icon={path === 'shared-exams' ?
            <EQuizIcon /> :
            <EQuizIconOutlined />
          }
          sx={{
            mt: 1,
          }}
          labelSx={{
            fontSize: 10
          }}
        />
      </HasPermission>
      <HasPermission to={['add-quiz']}>
        <LeftBarItem
          label={t('leftBarComponent.quiz')}
          onClick={() => window.location.replace('/quiz')}
          icon={path === 'quiz' ?
            <QuizIcon /> :
            <QuizIconOutlined />
          }
          sx={{
            mt: 1,
          }}
          labelSx={{
            fontSize: 10
          }}
        />
      </HasPermission>
      <HasPermission to={['add-videos']}>
        <LeftBarItem
          label={t('leftBarComponent.vms')}
          onClick={() => window.location.replace('/vms')}
          icon={path === 'vms' ?
            <VmsIcon /> :
            <VmsIconOutlined />
          }
          sx={{
            mt: 1,
          }}
          labelSx={{
            fontSize: 10
          }}
        />
      </HasPermission>
      {(state.auth?.groups && state.auth.groups.includes('lms-admin')) && (
        <LeftBarItem
          label={t('leftBarComponent.accounts')}
          onClick={() => window.location.replace(config.accountsUrl)}
          icon={<AccountIcon />}
          sx={{
            mt: 1,
          }}
          labelSx={{
            fontSize: 10
          }}
        />
      )}
    </LeftBar>
  )
}
