import { blue, grey, pink } from '@mui/material/colors';
import { createTheme, ThemeOptions } from '@mui/material/styles';

const primary = blue;
const secondary = pink;

export const common: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 580,
      md: 940,
      lg: 1260,
      xl: 1850
    },
  },
  typography: {
    fontFamily: 'Poppins, Arial',
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.9rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          '& .span': {
            fontSize: '0.9rem',
          },
        },
      },
    },
  },
};

export const getListOverrides = (selectedColor: string) => ({
  MuiListItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          position: 'relative',
          '&:before': {
            top: 0,
            right: 0,
            width: 3,
            bottom: 0,
            content: `''`,
            position: 'absolute',
            backgroundColor: selectedColor,
          },
          '& .MuiListItemIcon-root, & .MuiListItemText-root': {
            color: selectedColor,
          },
          '& .MuiListItemIcon-root svg': {
            fill: selectedColor,
          },
        },
      },
    },
  },
});

export const makeTheme = (opts: ThemeOptions) => createTheme({
  ...common,
  ...opts,
});

export const darkTheme = makeTheme({
  components: {
    ...common.components,
    ...getListOverrides(primary[200]),
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: grey[50]
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: grey[50]
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: primary[300],
    },
    secondary: {
      main: secondary[400],
    },
    background: {
      default: '#071A2C',
      paper: '#001E3C'
    },
  },
});

export const lightTheme = makeTheme({
  components: {
    ...common.components,
    ...getListOverrides(primary[700]),
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: grey[800]
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: grey[800]
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: primary[700],
    },
    secondary: {
      main: secondary[500],
    },
    background: {
      default: '#F7F9FC',
      paper: '#fff',
    },
  },
});