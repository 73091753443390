import { Grid, IconButton, Menu, MenuProps, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import accountsIcon from '../assets/app_menu_icons/accounts.svg';
import eQuizzesIcon from '../assets/app_menu_icons/e-quiz.svg';
import opticalScanIcon from '../assets/app_menu_icons/optical-scan.svg';
import quizzesIcon from '../assets/app_menu_icons/quizzes.svg';
import uzdemCoursesIcon from '../assets/app_menu_icons/courses.svg';
import vmsIcon from '../assets/app_menu_icons/vms.svg';
import { ReactComponent as NavIcon } from '../assets/nav-icon.svg';
import { StateContext } from '../contexts/State';
import { useAbility } from '../hooks/useAbility';
import AppMenuItem from './AppMenuItem';
import ConfigContext from '../contexts/ConfigContext';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

type ServiceNavMenuProps = {
  iconInheritColor?: boolean;
};

export default function ServiceNavMenu({ iconInheritColor }: ServiceNavMenuProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { can } = useAbility();
  const { state } = useContext(StateContext);
  const { config } = useContext(ConfigContext);
  const theme = useTheme();

  //Only 2 of them can be active in this application. uzdem and e-quiz => ['', 'shared-exams']
  const pathEls = window.location.pathname.split('/');
  let firstPath = pathEls.length >= 1 ? pathEls[1] : '';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="dialog-apps-open"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        color={iconInheritColor ? 'inherit' : 'primary'}
        sx={{
          p: 0,
          mx: 1
        }}
      >
        <NavIcon
          style={{
            fill: theme.palette.mode === 'dark' ? grey[50] : grey[800],
          }} />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={() => { handleClose() }}
      >
        <Grid container spacing={1} sx={{ width: 320, pr: 2, pl: 1, pb: 1, margin: 0 }}>
          <AppMenuItem itemColumnSpan={7} title={t('serviceNavMenu.courses')} iconSrc={uzdemCoursesIcon} path="/" isActive={firstPath !== 'shared-exams'} />
          {can('search-e-quizzes') && (
            <AppMenuItem itemColumnSpan={5} title={t('serviceNavMenu.eQuiz')} iconSrc={eQuizzesIcon} path="/shared-exams" isActive={firstPath === 'shared-exams'} />
          )}
          {can('add-quiz') && (
            <AppMenuItem itemColumnSpan={6} title={t('serviceNavMenu.quiz')} iconSrc={quizzesIcon} path="/quiz" />
          )}
          {can('do-omr-scan') && (
            <AppMenuItem itemColumnSpan={6} title={t('serviceNavMenu.opticalScan')} iconSrc={opticalScanIcon} path="/quiz/editor/global-optical-read" />
          )}
          {can('add-videos') && (
            <AppMenuItem itemColumnSpan={5} title={t('serviceNavMenu.vms')} iconSrc={vmsIcon} path="/vms" />
          )}
          {(state.auth?.groups && state.auth.groups.includes('lms-admin')) && (
            <AppMenuItem itemColumnSpan={7} title={t('serviceNavMenu.account')} iconSrc={accountsIcon} path={config.accountsUrl} />
          )}
        </Grid>
      </StyledMenu>
    </div>
  );
}
