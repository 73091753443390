import { SxProps } from '@mui/system';
import { nanoid } from 'nanoid';
import { grey } from '@mui/material/colors';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fixUtf8 = require('fix-utf8');

declare var plausible: any;

export const sendToPlausibleEvent = (goal: string, props: any) =>
  plausible && plausible(goal, props);

export const titleCase = (str: string) => {
  return str
    .toLocaleLowerCase('tr-TR')
    .replace(/(?:^|\s)[a-z0-9ğçşüöı]/g, function (match) {
      return match.toLocaleUpperCase('tr-TR');
    });
};

export const downloadFile = (url: string, name?: string) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const fileUrl = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = fileUrl;
      a.download = name || url.replace(/\[|\]|\(|\)/gi, '_');
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });
};

export const supportFileExtensionsMarkdownViewer = [
  'rar',
  'zip',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'csv',
  'ppt',
  'pptx',
  'mp3',
  'wav',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'ico',
  'mp4',
  'webm',
  'json',
  'psd',
  'sh',
  'exe',
  'iso',
  'bat',
  'pdf',
  'deb',
  'odt',
];

export const renameOriginalFileName = (fileName: string) => {
  const idLength = 6;

  const charMap: any = {
    Ç: 'C',
    ç: 'c',
    Ğ: 'G',
    ğ: 'g',
    ı: 'i',
    İ: 'I',
    Ö: 'O',
    ö: 'o',
    Ş: 'S',
    ş: 's',
    Ü: 'U',
    ü: 'u',
  };
  // fix non utf8 characters
  const safeFileName = fixUtf8(fileName)
    // remove illegal characters
    .replace(/[/\\?%*:|"<\s>]/g, '-')
    // replace turkish characters
    .replace(new RegExp(Object.keys(charMap).join('|'), 'g'), (c: string) =>
      charMap.hasOwnProperty(c) ? charMap[c] : '/',
    );

  const dotIndex = safeFileName.lastIndexOf('.');
  if (dotIndex === -1) return safeFileName + '_' + nanoid(idLength);

  return (
    safeFileName
      // add an id to end of it
      .slice(0, dotIndex) +
    '_' +
    nanoid(idLength) +
    safeFileName.slice(dotIndex - 1)
  );
};

export const reorder = (
  list: any[],
  startIndex: number,
  endIndex: number,
): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const imageUrlConvertWebpSrcList = (
  aipUrl: string,
  imageUrl: string,
  imageSize?: number,
) => {
  let parser = document.createElement('a');
  parser.href = imageUrl;
  const imageFolder =
    parser.pathname.substring(0, parser.pathname.lastIndexOf('/')) + '/';
  const imageName = parser.pathname.substring(
    parser.pathname.lastIndexOf('/') + 1,
  );
  const imageSizes = imageSize ? [imageSize] : [400, 800, 1200, 1600, 2000];
  const srcList = imageSizes.map(
    (size) =>
      `${aipUrl}/format_webp,w_${size + imageFolder + imageName} ${size}w`,
  );
  return srcList;
};

export const getSideNavStyle = ({
  theme,
  level = 1,
  paddingLeft = 0,
}: {
  theme: any;
  level?: number;
  paddingLeft?: number;
}): SxProps => {
  return {
    borderRadius: '50px',
    padding: `2px 6px 2px ${level > 1 ? `${paddingLeft + 8}px` : '24px'}`,
    margin: '0px 8px 4px 8px',
    width: 'calc(100% - 16px)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    ' & .MuiListItemText-root': {
      color: theme.palette.text.primary,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.mode === 'dark' ? grey[50] : grey[800],
    },
    '&.Mui-selected': {
      '&:before': {
        width: 0,
      },
      backgroundColor: theme.palette.primary.main + '2A',
      '&:hover': {
        backgroundColor: theme.palette.primary.main + '3A',
      },
      '& .MuiListItemText-root': {
        color: theme.palette.text.primary,
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.mode === 'dark' ? grey[50] : grey[800],
      },
      '& .MuiListItemIcon-root svg': {
        fill: theme.palette.mode === 'dark' ? grey[50] : grey[800],
      },
    },
  };
};

export const getBaseUrl = (domain: string) => 'https://' + domain;
