import { useState, useCallback } from "react";

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";

const ConfirmDialogContext = React.createContext<{
  opened: boolean
  confirm: (title: string, message?: string) => Promise<boolean>
}>({
  opened: false,
  confirm: title => Promise.resolve(false)
});


const ConfirmDialogProvider: React.FC = ({ children }) => {
  const [opened, setOpened] = useState(false);
  const [options, setOptions] = useState<{
    title: string,
    message?: string,
    resolve?: (val: boolean) => void
  }>({ title: '' });
  const { t } = useTranslation();

  const confirm = (title: string, message?: string) => new Promise<boolean>((resolve) => {
    setOpened(true);
    setOptions({ title, message, resolve });
    return
  });

  const handleClose = useCallback(() => {
    setOpened(false);
    setOptions({ title: '' });
  }, []);

  const answer = useCallback((val: boolean) => {
    handleClose();
    options.resolve && options.resolve(val);
  }, [options, handleClose]);

  return (
    <ConfirmDialogContext.Provider value={{ opened, confirm }}>
      <Dialog open={opened}>
        <DialogTitle>{options.title}</DialogTitle>
        {options?.message &&
          <DialogContent>
            <DialogContentText>{options?.message}</DialogContentText>
          </DialogContent>
        }
        <DialogActions>
          <Button onClick={_ => answer(false)}>{t('dialogProvider.cancel')}</Button>
          <Button onClick={_ => answer(true)} color="primary" variant="contained">{t('dialogProvider.ok')}</Button>
        </DialogActions>
      </Dialog>
      {children}
    </ConfirmDialogContext.Provider>
  );
}

export { ConfirmDialogContext, ConfirmDialogProvider };
