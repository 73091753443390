
export type Permission = (
  'add-books' |
  'add-categories' |
  'add-courses' |
  'add-entry' |
  'add-export' |
  'add-jobs' |
  'add-levels' |
  'add-pre' |
  'add-preview' |
  'add-publishers' |
  'add-question' |
  'add-questions' |
  'add-quiz' |
  'add-quiz-categories' |
  'add-result' |
  'add-section' |
  'add-sections' |
  'add-tags' |
  'add-tusd-hooks' |
  'add-units' |
  'add_uzdemuser' |
  'add-videos' |
  'add-slides' |
  'add-vms-folders' |
  'add-vms-metafields' |
  'add-vms-pictures' |
  'add-vms-videos' |
  'change_uzdemuser' |
  'delete-books' |
  'delete-categories' |
  'delete-courses' |
  'delete-entry' |
  'delete-export' |
  'delete-jobs' |
  'delete-levels' |
  'delete-pre' |
  'delete-preview' |
  'delete-publishers' |
  'delete-question' |
  'delete-questions' |
  'delete-quiz' |
  'delete-quiz-categories' |
  'delete-result' |
  'delete-section' |
  'delete-sections' |
  'delete-tags' |
  'delete-units' |
  'delete_uzdemuser' |
  'delete-videos' |
  'delete-vms-folders' |
  'delete-vms-metafields' |
  'delete-vms-pictures' |
  'delete-vms-videos' |
  'do-change-password' |
  'quiz-change-isonline' |
  'read-books' |
  'read-categories' |
  'read-courses' |
  'read-entry' |
  'read-export' |
  'read-images' |
  'read-jobs' |
  'read-levels' |
  'read-pre' |
  'read-preview' |
  'read-publishers' |
  'read-question' |
  'read-questions' |
  'read-quiz' |
  'read-quiz-categories' |
  'read-result' |
  'read-section' |
  'read-sections' |
  'read-tags' |
  'read-units' |
  'read-videos' |
  'read-vms-folders' |
  'read-vms-metafields' |
  'read-vms-pictures' |
  'read-vms-videos' |
  'search-books' |
  'search-categories' |
  'search-courses' |
  'search-entry' |
  'search-export' |
  'search-jobs' |
  'search-levels' |
  'search-pre' |
  'search-preview' |
  'search-progress-unit' |
  'search-publishers' |
  'search-question' |
  'search-questions' |
  'search-quiz' |
  'search-quiz-categories' |
  'search-result' |
  'search-section' |
  'search-sections' |
  'search-tags' |
  'search-units' |
  'search-videos' |
  'search-vms-folders' |
  'search-vms-metafields' |
  'search-vms-pictures' |
  'search-vms-videos' |
  'view_uzdemuser' |
  'write-books' |
  'write-categories' |
  'write-courses' |
  'write-coverimg' |
  'write-entry' |
  'write-export' |
  'write-jobs' |
  'write-levels' |
  'write-pre' |
  'write-preview' |
  'write-progress-unit' |
  'write-publishers' |
  'write-question' |
  'write-questions' |
  'write-quiz' |
  'write-quiz-categories' |
  'write-result' |
  'write-section' |
  'write-sectionpatch' |
  'write-sections' |
  'write-tags' |
  'write-units' |
  'write-uploads' |
  'write-videos' |
  'write-vms-folders' |
  'write-vms-metafields' |
  'write-vms-pictures' |
  'write-vms-videos' |
  'hide-teacher' |
  'quiz-change-isonline' |
  'hide-lms-content-manager' |
  'search-e-quizzes' | 
  'do-omr-scan'
);
type User = {
  id: number
  username: string
  email: string
  first_name: string
  last_name: string
}

export enum AuthActions {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  ClearToken = 'CLEARTOKEN',
}

export type AuthAction =
  | { type: AuthActions.Login, token: string, refreshToken: string | null }
  | { type: AuthActions.Logout }
  | { type: AuthActions.ClearToken }
  ;

export type AuthState = {
  user?: User
  expires?: Date,
  groups?: string[]
  levels?: string[]
  permissions?: Permission[]
  token?: string
  refreshToken?: string | null
  last_login?: string
  isRefreshing?: boolean
  isAuthInit?: boolean
}

export const authReducer = (state: AuthState, action: AuthAction) => {
  console.debug('Action received', action);
  switch (action.type) {
    case AuthActions.Login:
      if (action.token) {
        const { user, groups, levels, permissions, exp, last_login } = decodeJwt(action.token);
        return { user, groups, levels, permissions, last_login, expires: new Date(exp * 1000), token: action.token, refreshToken: action.refreshToken, isRefreshing: false, isAuthInit: true }
      } else {
        return { isAuthInit: true, isRefreshing: false }
      }
    case AuthActions.Logout:
      localStorage.removeItem('uzdem_token');
      localStorage.removeItem('refresh_token');
      window.location.reload(); //all cache clean
      return { isRefreshing: false, isAuthInit: true } // set empty state to logout
    case AuthActions.ClearToken:
      localStorage.removeItem('uzdem_token');
      return { isRefreshing: true, isAuthInit: true } // set empty state to logout
    default:
      return state;
  }
}

function decodeJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
