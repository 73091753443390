import {
  Search as SearchIcon
} from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MS, MSHits } from '../client';
import ExploreSearch from '../components/ExploreSearch';
import useAuthHeader from '../hooks/useAuthHeader';
import { useDeviceDetect } from '../hooks/useDeviceDetect';

type ExplorerSearchContainerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  buttonHide?: boolean;
}

export default function ExploreSearchContainer({ open, setOpen, buttonHide }: ExplorerSearchContainerProps) {

  const { t } = useTranslation();
  const { isMac } = useDeviceDetect();
  const headers = useAuthHeader();
  const [searchResults, setSearchResults] = useState<MSHits[]>([]);
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const ref = useRef<HTMLButtonElement>(null);

  const fetchData = useCallback(async () => {
    const res = await fetch(`/api/search/multi_search?q=${searchText}`, {
      method: 'POST',
      headers: {
        Authorization: headers.Authorization,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        searches: [
          {
            collection: 'courses',
            query_by: 'name,description,tags,category'
          },
          {
            collection: 'units',
            query_by: 'name,description,course,section'
          }
        ]
      })
    });

    const data = await res.json() as MS;
    if (data.results) {
      const results = data.results.map(r => r.hits).reduce((a, b) => a.concat(b));
      setSearchResults(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers.Authorization, searchText]);

  useEffect(() => {
    setSearchResults([]);
    if (searchText.length > 0) {
      fetchData();
    }
  }, [fetchData, searchText]);

  const handleClose = () => {
    setOpen(false);
    if (ref.current) {
      ref.current.blur();
    }
  };

  return (
    <>
      {!buttonHide &&
        <Button
          ref={ref}
          variant="outlined"
          onClick={() => {
            setOpen(true)
          }}
          size="large"
          sx={{
            width: { sm: 130, md: 450 },
            textAlign: 'left',
            height: 44,
            border: 'none',
            backgroundColor: theme => theme.palette.primary.main + '1A',
            color: theme => theme.palette.text.secondary,
            borderRadius: '14px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: theme => theme.palette.primary.main + '2A',
              border: 'none',
            },
          }}
          startIcon={<SearchIcon />}
          endIcon={
            <Typography
              sx={{
                fontSize: '14px !important',
                display: { xs: 'none', lg: 'inherit' },
                backgroundColor: theme => theme.palette.primary.main + '3A',
                padding: '4px 12px',
                borderRadius: 1,
                color: 'inherit',
              }}
            >
              {isMac ? '⌘' : 'Ctrl'}+K
            </Typography>
          }
        >
          <Box sx={{ width: '100%' }}>{!matchesMd ? t('exploreSearch.search') : t('exploreSearch.shortSearch')}</Box>
        </Button>
      }
      <Dialog fullScreen={matches} onClose={handleClose} open={open}>
        <DialogContent sx={{ p: 0 }}>
          <ExploreSearch searchResults={searchResults} inputValue={searchText} setSearchText={setSearchText} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
