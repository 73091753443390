import { Theme } from '@mui/material/styles';
import React, { createContext, useEffect, useState } from 'react';
import { CustomPalette } from '../client';
import { usePreferredColorScheme } from '../hooks/usePreferredColorSheme';
import { common, darkTheme, lightTheme, makeTheme, getListOverrides } from '../theme';
import { grey } from '@mui/material/colors';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

type DynamicThemeType = {
  theme: Theme;
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
  setCourseId: (id: number) => void;
  setCustomPalette: (palette?: CustomPalette) => void;
  customPalette: CustomPalette | undefined;
}

const initialValues: DynamicThemeType = {
  theme: lightTheme,
  darkMode: false,
  setDarkMode: (value: boolean) => { },
  setCourseId: (id: number) => { },
  setCustomPalette: (palette?: CustomPalette) => { },
  customPalette: undefined,
}

const DynamicThemeContext = createContext(initialValues);

const DynamicThemeProvider: React.FC = ({ children }) => {

  const scheme = usePreferredColorScheme();
  const [darkMode, setDarkMode] = useState(scheme === 'dark');
  const [courseId, setCourseId] = useState<number>();
  const [customPalette, setCustomPalette] = useState<CustomPalette>();
  const [theme, setTheme] = useState<Theme>(lightTheme);

  useEffect(() => {
    setDarkMode(scheme === 'dark');
  }, [scheme])

  useEffect(() => {
    if (!courseId) {
      setCustomPalette(undefined);
      return setTheme(darkMode ? darkTheme : lightTheme);
    }
    if (!customPalette) return;
    if (!customPalette.darkVibrant || !customPalette.lightVibrant) return;
    setTheme(
      makeTheme({
        components: {
          ...common.components,
          ...getListOverrides(darkMode ? customPalette.lightVibrant : customPalette.darkVibrant),
          MuiTypography: {
            styleOverrides: {
              root: {
                color: darkMode ? grey[50] : grey[800],
              }
            }
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                color: darkMode ? grey[50] : grey[800],
              },
            }
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: darkMode ? grey[50] : grey[800],
              }
            }
          }
        },
        palette: {
          mode: darkMode ? 'dark' : 'light',
          primary: {
            main: darkMode ? customPalette.lightVibrant : customPalette.darkVibrant
          },
          secondary: {
            main: darkMode ? customPalette.darkVibrant : customPalette.lightVibrant
          },
          background: {
            default: darkMode ? '#071A2C' : '#F7F9FC',
            paper: darkMode ? '#001E3C' : '#fff',
          },
        }
      })
    );
  }, [courseId, darkMode, customPalette])

  return (
    <DynamicThemeContext.Provider
      value={{
        theme,
        darkMode,
        setDarkMode,
        setCourseId,
        setCustomPalette,
        customPalette,
      }}>
      {children}
    </DynamicThemeContext.Provider>
  )
}

export { DynamicThemeContext, DynamicThemeProvider };
