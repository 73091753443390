import useFetch from 'fetch-suspense';
import useAuthHeader from './useAuthHeader';

export default function useFetchAuth(url: any, props?: any) {
  const auth = useAuthHeader();

  return useFetch(url, {
    ...props,
    headers: { ...props?.headers, ...auth }
  }, {
    lifespan: 900000 // 15 mins
  });
}
