import { useContext } from 'react';
import { StateContext } from '../contexts/State';

type AuthHeaders = {
  'content-type': 'application/json',
  'Authorization': string,
  'Accept-Language': string,
}
export default function useAuthHeader(): AuthHeaders {
  const { state } = useContext(StateContext);

  return { 'content-type': 'application/json', 'Accept-Language': state.preference.locale || 'tr', 'Authorization': (state.auth.token && 'Bearer ' + state.auth.token) || '' };
}
