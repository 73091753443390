import { useContext, useEffect } from 'react';
import { StateContext } from '../contexts/State';
import { AuthActions } from '../reducers/auth';

export default function usePersistedToken() {
  const { state, dispatch } = useContext(StateContext);

  // read persisted token
  useEffect(() => {
    const token = localStorage.getItem('uzdem_token') || '';
    const refreshToken = localStorage.getItem('refresh_token') || '';
    // dispatch login action with token read from local storage
    dispatch({ type: AuthActions.Login, token, refreshToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // runs once with dependency: []

  // store token when changed
  useEffect(() => {
    if (!state.auth.token) return;
    localStorage.setItem('uzdem_token', state.auth.token);
    localStorage.setItem('refresh_token', state.auth.refreshToken!);
  }, [state.auth.token]);
}
