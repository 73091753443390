import { useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

type RoutingScrollToTopProps = {
  children: React.ReactElement | null;
}

const useStyles = makeStyles(theme => ({
  body: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
    }
  }
}));

export default function RoutingScrollToTop({ children }: RoutingScrollToTopProps) {
  const { key, pathname } = useLocation();
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    const regex = new RegExp(/\/courses\/\d+/);
    if (regex.test(pathname) && matches) return;
    window.scrollTo(0, 0);
  }, [key, matches, pathname]);

  document.body.className = classes.body;

  return children;
}
