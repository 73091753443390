import { Box, List, Paper, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo-lms.png';
import darkLogo from '../assets/logo-lms-dark.png';
import NavGroup from './NavGroup';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh', // 64px Appbar
    width: '275px',
    position: 'fixed',
    left: 65,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    borderRight: 'none',
    background: 'transparent',
    zIndex: 11,
  },
  list: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    fontSize: 13
  },
  nav: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  dotDivider: {
    padding: theme.spacing(0, 2)
  },
  logo: {
    height: 42,
  },
  logoLink: {
    maxHeight: 42,
    maxWidth: 263, //275 - 12
    width: '100%',
    marginLeft: theme.spacing(3),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 64,
  },
  layerCover: ({ open }: { open: boolean }) => ({
    height: '100%',
    width: open ? 0 : 275,
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    position: 'absolute',
    background: theme.palette.background.default,
    right: 0,
    zIndex: 10,
  })
}));

export default function Sidenav({ open }: { open: boolean }) {
  const classes = useStyles({ open });
  const location = useLocation();
  const theme = useTheme();

  return (
    <Paper className={classes.root} elevation={0} >
      <Box className={classes.logoContainer}>
        <Link to="/" className={classes.logoLink}>
          <img
            className={classes.logo}
            src={theme.palette.mode === 'dark' ? darkLogo : logo}
            alt="Logo"
          />
        </Link>
      </Box>
      <List className={classes.list}>
        <div className={classes.nav}>
          <NavGroup
            activePath={location.pathname}
            activeQueryParams={location.search}
          />
        </div>
      </List>
      <Box className={classes.layerCover}>
      </Box>
    </Paper>
  );
}
