import { ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Category } from '../client';
import ExpandableListItem from '../components/ExpandableListItem';
import { CategoryListItemIcon } from './CategoryListItemIcon';
import { getSideNavStyle } from '../utils';

type CategoryListItemProps = {
  item: Category;
  selectedId: number | null;
  expandChildren?: boolean;
  onClick: (cat: Category) => void;
  level?: number;
};

export default function CategoryListItem({ item, selectedId, onClick, expandChildren, level = 1 }: CategoryListItemProps) {

  const theme = useTheme();

  const handleClick = (e: MouseEvent | null, cat: Category) => {
    e && e.stopPropagation();
    onClick(cat);
  };

  function nestedChildIncludes(cat: Category): boolean {
    if (!selectedId) return false;
    if (cat.id === selectedId) return true;
    if (cat.children && cat.children.length > 0) {
      return Boolean(cat.children.find(it => nestedChildIncludes(it)));
    }
    return false;
  }

  if (item.children && item.children.length > 0) {
    return (
      <ExpandableListItem
        expanded={expandChildren || nestedChildIncludes(item)}
        onClick={() => handleClick(null, item)}
        paddingLeft={level * 8}
        content={(
          <>
            <CategoryListItemIcon icon={item.icon} />
            <ListItemText primary={item.name} />
          </>
        )}
        collapse={(
          <>
            {item.children.map(item => (
              <CategoryListItem
                key={`category-${item.id}`}
                onClick={onClick}
                item={item}
                selectedId={selectedId}
                level={level + 1}
              />
            ))}
          </>
        )}
      />
    );
  }

  return (
    <ListItem button
      selected={selectedId === item.id}
      sx={getSideNavStyle({ theme, level, paddingLeft: level * 8 + 8 })}
      onClick={(e) => {
        e.preventDefault();
        onClick(item);
      }}
    >
      <CategoryListItemIcon icon={item.icon} />
      <ListItemText primary={
        <Typography
          sx={{
            fontSize: '14.4px',
          }}
          noWrap
        >
          {item.name}
        </Typography>
      } />
    </ListItem>
  )
}
