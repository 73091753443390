import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import braceUrl from '../assets/brace.svg';
import logoUrl from '../assets/logo-main.svg';
import { useContext } from 'react';
import ConfigContext from '../contexts/ConfigContext';

const Logo = (props: {
  style?: React.CSSProperties;
}) => <ReactSVG {...props} src={logoUrl} />;

const Brace = (props: {
  style?: React.CSSProperties;
}) => <ReactSVG {...props} src={braceUrl} />;


export default function Footer() {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ mt: 3, zIndex: 11 }} component="footer">
      <Brace />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: { xs: 1, lg: 2, xl: 8 },
          py: { xs: 1, sm: 1, md: 2 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: ['column', 'row'],
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              color: theme.palette.text.secondary,
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            {t('footer.altText')}
          </Typography>
          <Typography
            component="a"
            href={`mailto:${config.supportMail}`}
            sx={{
              textDecoration: 'none',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
              color: theme.palette.primary.main,
              cursor: 'pointer',
              '&:hover': { color: theme.palette.primary.dark },
            }}
          >
            {`${config.supportMail}`}
          </Typography>
        </Box>
        <Logo />
      </Box>
    </Box>
  );
}
