import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiList, Category } from '../client';
import CategoryListItem from '../components/CategoryListItem';
import { apiUrl } from '../config';
import useCache from '../hooks/useCache';
import useFetchAuth from '../hooks/useFetchAuth';

export default function CategoriesListContainer(props: { expandAll?: boolean, setCategoryCount?: (count: number) => void }) {
  const { cacheId } = useCache('categories');
  const categories = useFetchAuth(`${apiUrl}/categories?cacheId=${cacheId}&parentId=null&orderBy=priority:asc&take=1000`) as ApiList<Category>;
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const _selectedCatId = params.get('categoryId');
  const selectedCatId = _selectedCatId ? +_selectedCatId : null;

  useEffect(() => {
    categories && props.setCategoryCount && props.setCategoryCount(categories.total);
  }, [categories, props])

  const handleClick = (cat: Category) => {
    if (!cat.children || cat.children.length === 0) navigate(`/courses?categoryId=${cat.id}`);
  }

  return (
    <>
      {categories && categories.results && categories.results.map(cat => (
        <CategoryListItem
          key={`nav-category-${cat.id}`}
          item={cat}
          selectedId={selectedCatId}
          onClick={handleClick}
          expandChildren={props.expandAll}
        />
      ))}
    </>
  );
}
