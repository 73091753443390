import { AppBar, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState } from 'react';
import ExploreSearchContainer from '../containers/ExploreSearchContainer';
import { StateContext } from '../contexts/State';
import { useAbility } from '../hooks/useAbility';
import AppBarUserMenu from './AppBarUserMenu';
import ServiceNavMenu from './ServiceNavMenu';

const useStyles = makeStyles(theme => ({
  root: ({ sideNavOpen }: { sideNavOpen: boolean }) => ({
    background: theme.palette.background.default,
    height: 60,
    width: sideNavOpen ? `calc(100% - 275px - 65px + 12px)` : `calc(100% - 65px)`,
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  }),
  toolbar: ({ sideNavOpen }: { sideNavOpen: boolean }) => ({
    display: 'flex',
    paddingLeft: sideNavOpen ? theme.spacing(3) : `calc(${theme.spacing(3)} - 12px)`,
    transition: 'padding-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    justifyContent: 'space-between',
  }),
  checkedIcon: {
    color: `${theme.palette.secondary.main} !important`,
  },
  switchIcon: {
    background: theme.palette.background.paper,
    borderRadius: '50%',
    color: theme.palette.primary.main
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  userInfo: {
    display: 'flex',
    marginRight: 8,
    alignItems: 'center',
  },
  search: {
    width: '100%',
  },
}));

export default function Appbar({ sideNavOpen }: { sideNavOpen: boolean }) {
  const classes = useStyles({ sideNavOpen });
  const { can } = useAbility();
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const { state } = useContext(StateContext);

  return (
    <AppBar
      position="fixed"
      className={classes.root}
      color="inherit"
      elevation={0}
      enableColorOnDark
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.search}>
          {<ExploreSearchContainer open={searchDialogOpen} setOpen={setSearchDialogOpen} />}
        </div>
        <div className={classes.actions}>
          <Typography noWrap className={classes.userInfo} variant="body1">
            {state.auth.user?.first_name} {state.auth.user?.last_name || ''}
          </Typography>
          {(can('write-quiz') || can('read-vms-folders')) && <ServiceNavMenu />}
          <AppBarUserMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
}
