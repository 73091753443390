import { Icon, ListItemIcon } from '@mui/material';
import { CategoryRounded as DefaultIcon } from '@mui/icons-material';
import { ReactSVG } from 'react-svg';
import ConfigContext from '../contexts/ConfigContext';
import { useContext } from 'react';

type CategoryListItemIconProps = {
  icon?: string;
}

export const CategoryListItemIcon = ({ icon }: CategoryListItemIconProps) => {
  const { config } = useContext(ConfigContext);

  return( <ListItemIcon sx={{ minWidth: '40px' }}>
    {icon ? (
      <Icon className="notranslate">
        {icon.includes('.svg') ? (
          <ReactSVG
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
            style={{ fill: 'currentColor' }}
            src={`${config.s3IconsUrl}/${icon}`}
          />
        ) : icon}
      </Icon>
    ) : <DefaultIcon />}
  </ListItemIcon>)
};
