import {
  CollectionsBookmark as AllCoursesFilledIcon,
  CollectionsBookmarkOutlined as AllCoursesOutlinedIcon,
  Leaderboard as CatalogsFilledIcon,
  LeaderboardOutlined as CatalogsOutlinedIcon,
  Category as CategoryFilledIcon,
  CategoryOutlined as CategoryOutlinedIcon,
  LocalActivity as HomeFilledIcon,
  LocalActivityOutlined as HomeOutlinedIcon,
  AccountBox as MyPageFilledIcon,
  AccountBoxOutlined as MyPageOutlinedIcon,
  PhotoLibrary as SlideFilledIcon,
  PhotoLibraryOutlined as SlideOutlinedIcon,
  Label as TagsFilledIcon,
  LabelOutlined as TagsOutlinedIcon
} from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, ListSubheader, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CategoriesListContainer from '../containers/CategoriesList';
import HasPermission from '../containers/HasPermission';
import { getSideNavStyle } from '../utils';

type Props = {
  activePath: string;
  activeQueryParams: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  hierarchyTitle: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: 40
  }
}));

export default function NavGroup({ activePath, activeQueryParams }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [categoryCount, setCategoryCount] = useState(0);
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <ListItem sx={getSideNavStyle({ theme })} button component={Link} to="/" selected={activePath === '/'}>
        <ListItemIcon className={classes.listItemIcon}>
          {activePath === '/' ? <HomeFilledIcon /> : <HomeOutlinedIcon />}
        </ListItemIcon>
        <ListItemText primary={t('navGroup.explore')} />
      </ListItem>
      <ListItem sx={getSideNavStyle({ theme })} button component={Link} to="/my-page" selected={activePath === '/my-page'}>
        <ListItemIcon className={classes.listItemIcon}>
          {activePath === '/my-page' ? <MyPageFilledIcon /> : <MyPageOutlinedIcon />}
        </ListItemIcon>
        <ListItemText primary={t('navGroup.myPage')} />
      </ListItem>

      <ListItem sx={getSideNavStyle({ theme })} button component={Link} to="/courses" selected={activePath === '/courses' && !activeQueryParams}>
        <ListItemIcon className={classes.listItemIcon}>
          {(activePath === '/courses' && !activeQueryParams) ? <AllCoursesFilledIcon /> : <AllCoursesOutlinedIcon />}
        </ListItemIcon>
        <ListItemText primary={t('navGroup.allCourses')} />
      </ListItem>

      {categoryCount > 0 && <ListSubheader disableSticky className={classes.hierarchyTitle}>{t('navHierarchy.categories')}</ListSubheader>}
      <CategoriesListContainer setCategoryCount={setCategoryCount} />

      <HasPermission to={['add-categories' || 'add-tags' || 'add-levels' || 'add-quiz']}>
        <ListSubheader disableSticky className={classes.hierarchyTitle}>{t('navHierarchy.contentManagement')}</ListSubheader>
      </HasPermission>


      <HasPermission to={['add-categories']}>
        <ListItem sx={getSideNavStyle({ theme })} button component={Link} to="/categories" selected={activePath === '/categories'}>
          <ListItemIcon className={classes.listItemIcon}>
            {activePath === '/categories' ? <CategoryFilledIcon /> : <CategoryOutlinedIcon />}
          </ListItemIcon>
          <ListItemText primary={t('navGroup.categories')} />
        </ListItem>
      </HasPermission>

      <HasPermission to={['add-tags']}>
        <ListItem sx={getSideNavStyle({ theme })} button component={Link} to="/tags" selected={activePath === '/tags'}>
          <ListItemIcon className={classes.listItemIcon}>
            {activePath === '/tags' ? <TagsFilledIcon /> : <TagsOutlinedIcon />}
          </ListItemIcon>
          <ListItemText primary={t('navGroup.tags')} />
        </ListItem>
      </HasPermission>

      <HasPermission to={['add-levels']}>
        <ListItem sx={getSideNavStyle({ theme })} button component={Link} to="/levels" selected={activePath === '/levels'}>
          <ListItemIcon className={classes.listItemIcon}>
            {activePath === '/levels' ? <CatalogsFilledIcon /> : <CatalogsOutlinedIcon />}
          </ListItemIcon>
          <ListItemText primary={t('navGroup.levels')} />
        </ListItem>
      </HasPermission>

      <HasPermission to={['add-slides']}>
        <ListItem sx={getSideNavStyle({ theme })} button component={Link} to="/slides" selected={activePath === '/slides'}>
          <ListItemIcon className={classes.listItemIcon}>
            {activePath === '/slides' ? <SlideFilledIcon /> : <SlideOutlinedIcon />}
          </ListItemIcon>
          <ListItemText primary={t('navGroup.slides')} />
        </ListItem>
      </HasPermission>
    </div>
  );
}
