import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';

type SnackbarCloseButtonProps = {
  snackbarKey?: SnackbarKey;
};
export default function SnackbarCloseButton({
  snackbarKey,
}: SnackbarCloseButtonProps) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close sx={{ color: '#FFF' }} />
    </IconButton>
  );
}
