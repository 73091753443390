// store.js
import React, { createContext, useReducer, Dispatch } from 'react';

import { AuthState, AuthAction, authReducer } from '../reducers/auth';
import { preferenceReducer, PreferenceState, PreferenceAction } from '../reducers/preference';
import { useTranslation } from 'react-i18next';
import { CacheAction, cacheReducer, CacheState } from '../reducers/cache';


type InitialStateType = {
  auth: AuthState,
  preference: PreferenceState,
  cache: CacheState,
}

const initialState: InitialStateType = {
  auth: {},
  preference: {},
  cache: {
    values: {
      courses: 1,
      levels: 1,
      levelsSearch: 1
    }
  }
}

type Action = AuthAction | PreferenceAction | CacheAction

const StateContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = ({ auth, preference, cache }: InitialStateType, action: Action) => ({
  auth: authReducer(auth, action as AuthAction),
  preference: preferenceReducer(preference, action as PreferenceAction),
  cache: cacheReducer(cache, action as CacheAction),
});

const StateProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  initialState.preference.locale = i18n.language === 'tr-TR' ? 'tr-TR' : 'en-US';
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
}

export { StateProvider, StateContext };
