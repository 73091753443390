import { Box, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import ExploreSearchContainer from '../containers/ExploreSearchContainer';
import MobileNavContainer from '../containers/MobileNav';
import Appbar from './Appbar';
import Footer from './Footer';
import LeftBarComponent from './LeftBarComponent';
import Sidenav from './Sidenav';

type Props = {
  children: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  desktop: ({ sideNavOpen }: { sideNavOpen: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: sideNavOpen ? 'calc(100% - 65px - 275px)' : 'calc(100% - 65px)',
    marginLeft: sideNavOpen ? 275 + 65 : 65,
    transition: 'all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    paddingTop: 60,
    height: '100vh',
    overflow: 'auto',
    zIndex: 28,
  }),
  main: {
    flex: 1,
    width: '100%',
    background: theme.palette.background.default,
    padding: theme.spacing(0, 1.5),
    zIndex: 28,
  },
  mobile: {
    display: 'flex',
    paddingBottom: 56, //56px BottomNav
    flexDirection: 'column',
    minHeight: '100%',
  },
  mobileMain: {
    flex: 1,
    overflow: 'auto',
    background: theme.palette.background.default,
    padding: theme.spacing(0, 1)
  },
}));

export default function Layout({ children }: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [sideNavOpen, setSideNavOpen] = useState(true);
  const classes = useStyles({ sideNavOpen });

  useEffect(() => {
    const openDialogFunction = (e: any) => {
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyK') {
        e.preventDefault();
        setSearchDialogOpen(true);
      }
    };

    document.addEventListener('keydown', openDialogFunction, false);
    return () => {
      document.addEventListener('keydown', openDialogFunction, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  }

  return matches ? ( // desktop mode
    <React.Fragment>
      <Box>
        <LeftBarComponent
          handleToggleSideNav={handleToggleSideNav}
          sideNavOpen={sideNavOpen}
        />
        <Sidenav
          open={sideNavOpen}
        />
        <Box>
          <Appbar sideNavOpen={sideNavOpen} />
          <div className={classes.desktop}>
            <div id="layout-main" className={classes.main}>
              {children}
            </div>
            <Footer />
          </div>
          <ExploreSearchContainer buttonHide open={searchDialogOpen} setOpen={setSearchDialogOpen} />
        </Box>
      </Box>
    </React.Fragment>
  ) : ( // mobile mode
    <div className={classes.mobile}>
      <div className={classes.mobileMain}>
        {children}
      </div>
      <MobileNavContainer />
      <Footer />
    </div>
  );
}
