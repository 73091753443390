import { Permission } from "../reducers/auth";
import { useContext } from "react";
import { StateContext } from "../contexts/State";

export function useAbility() {
  const { state } = useContext(StateContext);

  const can = (required: Permission | Permission[]): boolean => {
    if (Array.isArray(required)) {
      return required.every((p) => state.auth.permissions?.includes(p));
    } else {
      return state.auth.permissions ? state.auth.permissions.includes(required) : false;
    }
  }

  return { can };
}
