import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { LinearProgress } from '@mui/material';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

declare var SENTRY_DSN: string;

SENTRY_DSN !== '$SENTRY_DSN' && Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Suspense fallback={<LinearProgress />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//   onUpdate: () => {
//     const reload = window.confirm('Yeni bir güncelleme var. Yüklemek ister misiniz?');
//     if (reload) {
//       serviceWorkerRegistration.unregister();
//       window.location.reload();
//     }
//   }
// });
