import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import pkg from '../package.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'tr',
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + pkg.version
    },
    react: {
      useSuspense: false,
    },
    keySeparator: '.',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
